import React, { ReactNode, useCallback, useMemo } from 'react';
import { ApolloProvider as ParentProvider, ApolloClient, createHttpLink, split, from } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ConnectionParams } from 'subscriptions-transport-ws';
import * as Sentry from '@sentry/react';
import { cache } from './cache';
import { useTokenContext } from '../TokenProvider';
import { env } from 'env';
import { commitInfo } from 'commitInfo';

const DO_NOT_RETRY_OPERATIONS: string[] = [];
const RETRY_UP_TO_LIMIT_OPERATIONS: Record<string, number> = {
  getInvoiceWithLineItems: 1,
};
const DEFAULT_RETRY_LIMIT = 5;

const apolloClient = (getAuthHeaders: () => Record<string, string>, uri: string): ApolloClient<unknown> => {
  const httpLink = createHttpLink({
    uri,
  });

  const retryLink = new RetryLink({
    attempts: (count, operation, error): boolean => {
      // If there's a statusCode, don't retry (network errors do not have a status code; this also includes those exceeding the 60s timeout)
      if (error.statusCode) {
        return false;
      }

      // If this operation is not permitted to retry, do not allow a retry attempt
      if (DO_NOT_RETRY_OPERATIONS.includes(operation.operationName)) {
        return false;
      }

      // If `count` exceeds the retry limit, do not allow retries (note: the first retry is 1, so this must be >, not >=)
      if (count > RETRY_UP_TO_LIMIT_OPERATIONS[operation.operationName]) {
        return false;
      }

      // Otherwise, allow retry attempts
      return count < DEFAULT_RETRY_LIMIT;
    },
    delay: (count): number => {
      return count * 1000 * Math.random();
    },
  });

  const additiveLink = from([retryLink, httpLink]);

  const wsLink = new WebSocketLink({
    uri: env.REACT_APP_SUBSCRIPTIONS_URI || '',
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async (): Promise<ConnectionParams> => {
        return getAuthHeaders();
      },
      connectionCallback: (error): void => {
        if (error) {
          Sentry.captureException(error);
          console.log(error);
        }
      },
    },
  });

  const authLink = setContext(async (_, { headers }: { headers?: Record<string, string> }) => {
    return {
      headers: {
        ...getAuthHeaders(),
        ...headers,
        CommitHash: commitInfo.commitHash,
        CommitNumber: commitInfo.commitNumber,
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    additiveLink,
  );

  return new ApolloClient({
    link: authLink.concat(splitLink),
    cache,
    defaultOptions: {
      watchQuery: {
        /**
         * TODO: Evaluate reverting this to default recommended by Apollo Client
         *
         * Apollo Client 3.4 introduced a change to overwrite rather than merge existing data
         * during a refetch.
         *
         * Source: https://github.com/apollographql/apollo-client/blob/main/CHANGELOG.md#bug-fixes-28
         */
        refetchWritePolicy: 'merge',
      },
    },
    name: 'care-web',
    version: commitInfo.commitHash,
  });
};

interface IProps {
  // Only used for sockets because we cannot send headers via useSubscription
  //getAuthHeaders: () => Record<string, string>;
  uri: string;
  children: ReactNode;
}

// Todo: make sure it checks if it's authorized or not and falls back
const ApolloProvider = ({ uri, children }: IProps): JSX.Element => {
  const { getLiveToken } = useTokenContext();
  const getAuthHeaders = useCallback((): Record<string, string> => {
    const token = getLiveToken();
    if (token) {
      return {
        'auth-type': 'clinic_pet_parent_token',
        authorization: `Bearer ${token}`,
      };
    } else return {};
  }, [getLiveToken]);
  const client = useMemo(() => {
    return apolloClient(getAuthHeaders, uri);
  }, [uri, getAuthHeaders]);

  return <ParentProvider client={client}>{children}</ParentProvider>;
};

export default ApolloProvider;
