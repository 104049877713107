import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PathHistoryContext } from './contexts/PathHistoryContext';
import { IPathHistoryProvider } from './interfaces/IPathHistoryProvider';

interface PathHistoryProps {
  children: ReactNode;
}

export const usePathHistory = (): IPathHistoryProvider => useContext<IPathHistoryProvider>(PathHistoryContext);

const PathHistoryProvider = ({ children }: PathHistoryProps): JSX.Element => {
  const location = useLocation();
  const [pathHistory, setPathHistory] = useState<string[]>([]);

  useEffect(() => {
    setPathHistory((currentPathHistory) => {
      const previousPath = currentPathHistory[currentPathHistory.length - 1];
      if (location.pathname === previousPath) {
        return currentPathHistory;
      }

      return [...currentPathHistory, location.pathname];
    });
  }, [location]);

  return (
    <PathHistoryContext.Provider value={{ pathHistory, lastPath: pathHistory[pathHistory.length - 2] }}>
      {children}
    </PathHistoryContext.Provider>
  );
};

export default PathHistoryProvider;
