import React, { useEffect, useMemo, useState } from 'react';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import { ClinicAuth } from 'shared/types/ClinicAuth';
import { useRegeneratePetParentClinicListWithTokensMutation } from 'shared/types/graphql';
import usePersistedState from './usePersistedState';
import { LoginType } from '@televet/shared-types/JsonTypes/LoginType';

const useClinicListResync = (): {
  isLoading: boolean;
  setClinicPetParentId: React.Dispatch<React.SetStateAction<string | undefined>>;
} => {
  // If we are accessing a direct link copied from flow, we don't want to fetch any other clinics
  const queryParams = new URLSearchParams(location.search);
  const comingFromFlow = queryParams.get(PersistedStateKeys.LoginType) === LoginType.Flow;

  const shouldResync = useMemo(() => {
    if (comingFromFlow) {
      return false;
    }

    return localStorage.getItem(PersistedStateKeys.LoginType) !== LoginType.Flow;
    // comingFromFlow is the only dependency and if is present when accessing the page, means we should not resync
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [clinicPetParentId, setClinicPetParentId] = useState<string | undefined>();
  const [locallyStoredClinics, setLocallyStoredClinics] = usePersistedState<ClinicAuth[]>(
    PersistedStateKeys.ClinicAuthArray,
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [regeneratePetParentClinicListWithTokensMutation] = useRegeneratePetParentClinicListWithTokensMutation();

  useEffect(() => {
    if (comingFromFlow) {
      // Remove all clinics from local storage
      setLocallyStoredClinics([]);
    }
  }, [comingFromFlow, setLocallyStoredClinics]);

  // Temporary fix for due active's clinic property regression
  useEffect(() => {
    // Not even authenticated
    if (clinicPetParentId === undefined) {
      return;
    }

    if (clinicPetParentId && shouldResync) {
      regeneratePetParentClinicListWithTokensMutation()
        .then((res) => {
          if (res.data?.regeneratePetParentClinicListWithTokens) {
            // clinicPetParentMatches contains the latest active clinics for the user, our goal here is:
            // To update to inactive the ones we don't belong to anymore - this way those clinics will appear inactive until he logs out /in again
            // Fix clinic that were locally stored before Feb 7 / 2024
            const {
              clinicPetParentMatches,
            }: {
              clinicPetParentMatches: { token: string; clinicPetParent: { clinic: ClinicAuth } }[];
            } = res.data?.regeneratePetParentClinicListWithTokens;

            const petParentClinics = clinicPetParentMatches.map((pp) => ({
              ...pp.clinicPetParent.clinic,
              token: pp.token,
            }));

            const newClinics: ClinicAuth[] = [];

            for (const clinic of locallyStoredClinics) {
              const petParentClinic = petParentClinics.find(({ id }) => id === clinic.id);
              newClinics.push({ ...clinic, isClinicActive: !!petParentClinic?.isClinicActive });
            }

            setLocallyStoredClinics(newClinics);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // User is not authenticated or No need to resync
      setIsLoading(false);
    }

    // not including locallyStoredClinics since we only want this to happen once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldResync, clinicPetParentId, regeneratePetParentClinicListWithTokensMutation, setLocallyStoredClinics]);

  return { isLoading, setClinicPetParentId };
};

export default useClinicListResync;
