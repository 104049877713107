import jwt from 'jsonwebtoken';
import { ClientPermissions } from '../context/TokenContext';

export const isTokenValid = (token?: string | null): token is string => {
  return !!token && token !== 'null' && ((isSIRT(token) && !hasExpired(token)) || isSAT(token));
};
const hasExpired = (token: string): boolean => {
  const exp = decode(token)?.exp;
  if (exp) {
    return Date.now() >= +exp * 1000;
  }
  return false;
};
export const isSAT = (token: string): boolean => {
  // It's simple access if it has no scopes and no expiration
  const data = decode(token);
  // Special case for email campaigns
  if (
    data &&
    data.scopes &&
    data.scopes.length > 0 &&
    data.scopes.every((scope: string) => scope === ClientPermissions.EmailCampaign)
  ) {
    return true;
  }
  return !data?.scopes && !data?.exp;
};
export const isSIRT = (token: string): boolean => {
  return !isSAT(token);
};
export const matchingPetParents = (token1: string, token2: string): boolean => {
  const cpp = decode(token1)?.clinicPetParentId;
  return cpp !== null && cpp === decode(token2)?.clinicPetParentId;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const decode = (token?: string): { [key: string]: any } | null => {
  if (!token) return null;
  const data = jwt.decode(token);
  if (!data || typeof data === 'string') return null;
  return data;
};

window.decode = decode;

export const checkTokenScopes = (token: string, checkScopes: ClientPermissions | ClientPermissions[]): boolean => {
  const data = jwt.decode(token);
  if (data && typeof data !== 'string') {
    const scopes: ClientPermissions[] = data['scopes'] || [];
    if (Array.isArray(checkScopes)) {
      return checkScopes.some((scope) => scopes.some((s) => s === scope));
    } else {
      return scopes.some((s) => s === checkScopes);
    }
  }
  return false;
};
