import React, { useEffect, useRef } from 'react';
import { Router, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ProtectedRoute from './components/ProtectedRoute';
import NavigationToolbar from 'shared/components/NavigationToolbar/NavigationToolbar';
import { RouteDefinitions } from './enums/RouteDefinitions';
import { useCareAuthContext } from 'shared/providers/CareAuthProvider';
import PathHistoryProvider from 'shared/providers/PathHistoryProvider';
import loadable from '@loadable/component';
import useGA from 'shared/hooks/useGA';
import useHotjar from 'shared/hooks/useHotjar';
import { Center } from '@chakra-ui/layout';
import { Spinner } from '@televet/kibble-ui';

const Home = loadable(() => import('pages/Home'));
const Games = loadable(() => import('pages/Games'));
const Requests = loadable(() => import('pages/Requests'));
const PetsPage = loadable(() => import('pages/Pets/PetsPage'));
const PetPage = loadable(() => import('pages/Pets/PetPage'));
const PlansPage = loadable(() => import('pages/Plans/PlansPage'));
const ConversationsRedirectPage = loadable(() => import('pages/Conversations/ConversationsRedirectPage'));
const ConversationsListPage = loadable(() => import('pages/Conversations/ConversationsListPage'));
const CurbsideConversationsLayout = loadable(() => import('shared/components/Layouts/CurbsideConversationsLayout'));
const AccountPage = loadable(() => import('pages/Account/AccountPage'));
const ManageSubscriptions = loadable(() => import('pages/Account/components/ManageSubscriptions'));
const LoyaltyProgram = loadable(() => import('pages/Account/components/LoyaltyProgram'));
const CurbsideFormsLayout = loadable(() => import('shared/components/Layouts/CurbsideFormsLayout'));
const VideoCallLayout = loadable(() => import('shared/components/Layouts/VideoCallLayout'));
const AutomationRun = loadable(() => import('pages/Conversations/components/AutomationRun/AutomationRun'));
const Inactive = loadable(() => import('pages/Inactive'));

const Landing = loadable(() => import('../pages/Landing/Landing.component'));
const ClinicSelect = loadable(() => import('../pages/ClinicSelect/ClinicSelect.component'));
const PreAuthenticatedLayout = loadable(() => import('shared/components/Layouts/PreAuthenticatedLayout'));
const UnsubscribePage = loadable(() => import('pages/Unsubscribe'));
const EmailCampaignUnsubscribe = loadable(() => import('pages/EmailCampaignUnsubscribe/EmailCampaignUnsubscribe'));

export { RouteDefinitions };

const history = createBrowserHistory();

export const replaceParam = (route: string, param: string, replacement: string): string => {
  return route.replace(param, replacement);
};
const PrivateRoutes = (): JSX.Element => (
  <>
    <ProtectedRoute path={RouteDefinitions.Home} exact component={Home} />
    <ProtectedRoute path={RouteDefinitions.Requests} exact component={Requests} />
    <ProtectedRoute path={RouteDefinitions.Pets} exact component={PetsPage} />
    <ProtectedRoute path={RouteDefinitions.Pet} component={PetPage} />
    <ProtectedRoute path={RouteDefinitions.ConversationsRedirect} exact component={ConversationsRedirectPage} />
    <ProtectedRoute path={RouteDefinitions.ConversationsList} exact component={ConversationsListPage} />
    <ProtectedRoute path={RouteDefinitions.Conversation} exact component={CurbsideConversationsLayout} />
    <ProtectedRoute path={RouteDefinitions.Account} exact component={AccountPage} />
    <ProtectedRoute path={RouteDefinitions.ManagePlans} component={ManageSubscriptions} />
    <ProtectedRoute path={RouteDefinitions.LoyaltyProgram} component={LoyaltyProgram} />
    <ProtectedRoute path={RouteDefinitions.Forms} exact component={CurbsideFormsLayout} />
    <ProtectedRoute path={RouteDefinitions.VideoCall} exact component={VideoCallLayout} />
    <ProtectedRoute path={RouteDefinitions.AutomationRun} exact component={AutomationRun} />
    <ProtectedRoute path={RouteDefinitions.Plans} exact component={PlansPage} />
  </>
);

const Routes = (): JSX.Element => {
  const { clinicPetParent, isAuthenticated, isLoading } = useCareAuthContext();
  const { setUser, isInitialized } = useGA(clinicPetParent);

  useEffect(() => {
    if (!!clinicPetParent?.id && isInitialized) {
      setUser(clinicPetParent.id);
    }
  }, [clinicPetParent?.id, isInitialized, setUser]);

  const { identify: identifyHotjar } = useHotjar();
  useEffect(() => {
    if (!!clinicPetParent?.id) {
      identifyHotjar();
    }
  }, [clinicPetParent?.id, identifyHotjar]);

  const queryParamsRef = useRef(window.location.search);
  // Routes component re-renders after initial mount and on every state change (such as inputting text) wiping out the query params so we need to store them in a ref

  if (isLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }

  return (
    <Router history={history}>
      <PathHistoryProvider>
        <Switch>
          <Route
            path={RouteDefinitions.Index}
            exact
            component={(props: RouteComponentProps): JSX.Element => (
              <Landing {...props} queryParams={queryParamsRef.current} />
            )}
          />
          <Route path={RouteDefinitions.Inactive} exact component={Inactive} />
          <Route path={RouteDefinitions.ClinicSelectAuth} exact component={ClinicSelect} />
          <Route path={RouteDefinitions.ClinicSelect} exact component={ClinicSelect} />
          <Route path={RouteDefinitions.Login} exact component={PreAuthenticatedLayout} />
          <Route path={RouteDefinitions.Unsubscribe} exact component={UnsubscribePage} />
          <Route path={RouteDefinitions.Games} exact component={Games} />
          <Route path={RouteDefinitions.EmailCampaignUnsubscribe} exact component={EmailCampaignUnsubscribe} />
          {!isAuthenticated && !isLoading && <Redirect to={RouteDefinitions.Index} />}
          <NavigationToolbar>
            <PrivateRoutes />
          </NavigationToolbar>
        </Switch>
      </PathHistoryProvider>
    </Router>
  );
};

export default Routes;
